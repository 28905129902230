import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import AddCart from '../methods/products/add_cart'
Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_product'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      product: []
      price_usd: 0
      old_price_usd: 0
      price: 0
      old_price: 0
      quantity: 1
      min: 1
      max: undefined
      image: undefined
      units_available: 0
      has_discount: undefined
      percentage_discount: undefined
    }
    methods: {
      AddCart
    }

    mounted: ->
      id = document.getElementById('id').dataset.id
      title = document.getElementById('title').dataset.id
      query = $.get "/productos/#{title}-id_#{id}.json"
      query.done (res) =>
        @product = res.product
        @image = res.image
        @price = res.price
        @units_available = res.units_available
        @old_price = res.old_price
        @percentage_discount = res.percentage_discount
        @has_discount = res.has_discount
        @max = res.units_available
        @price_usd = res.price_usd
        @old_price_usd = res.old_price_usd
  }
